import { DataGrid } from 'assets/components/data-grid';
import React, {
  FunctionComponent,
  PropsWithChildren,
  memo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { GestureResponderEvent, View } from 'react-native';
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
  RowNode,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import shallow from 'zustand/shallow';
import { makeStyles, useTheme } from 'assets/theme';
import {
  ThreedotsVerticalIcon,
  CheckCircleIcon,
  BookmarkIcon,
  BookmarkIconFilledIn,
  UserPlusIcon,
  CalendarIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  MinusCircleIcon,
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  LockIcon,
} from 'assets/icons';
import { IconButton } from 'assets/components/icon-button';
import { Text } from 'assets/components/text';
import {
  TaskDto,
  TaskPageDto,
  TaskStatus,
  TaskPriority,
  TaskVisibility,
} from '@digitalpharmacist/tasks-service-client-axios';
import {
  persistBulkAction,
  persistCollapseSidebarMethod,
  persistGridApi,
  persistTaskPage,
  setContextMenuTask,
  showTaskDetails,
  updateTask,
} from './tasks-data-table-actions';
import '../../common/data-grid-overrides.css';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import 'react-contexify/dist/ReactContexify.css';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { Icon } from 'assets/components/icon';
import TaskContextMenu from './TaskContextMenu';
import {
  CheckboxInput,
  CheckboxInputMode,
} from '../../../../../packages/assets/components/checkbox';
import {
  enableFilters,
  setCounts,
} from '../tasks-filters/tasks-filters-actions';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import { Avatar } from 'assets/components/avatar/Avatar';
import {
  BulkActionType,
  useTasksDataTableState,
} from './tasks-data-table-store';
import BulkActionConfirmationDialog from './BulkActionsConfirmationDialog';
import BulkAssigneeDialog from './BulkAssigneeDialog';
import BulkDueDateDialog from './BulkDueDateDialog';
import {
  calculateTaskDueDate,
  composeFiltersMessage,
  getActionButtonColor,
  getActionButtonIcon,
} from './tasks-data-table.utils';
import { TaskStatusContextMenu } from './TaskStatusContextMenu';
import TaskService from '../../api/TaskService';
import { TaskMetaItem } from '../task-meta-item/TaskMetaItem';
import { zIndexAuto } from '../../common/theme';
import {
  TaskFilters,
  useTasksFiltersState,
} from '../tasks-filters/tasks-filters-store';
import { useFocusEffect } from '@react-navigation/native';
import useDebounceSearchTerm from '../tasks-filters/useDebounceSearchTerm';
import { PaginatedRemoteDatasource } from 'assets/components/data-grid/data-grid-toolkit/datasource/PaginatedRemoteDatasource';
import { getText } from 'assets/localization/localization';
import { ampli } from '../../src/ampli';

const MENU_ID = 'row-options';
const STATUS_MENU_ID = 'task-status';

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const TimeRenderer = memo((props: { data: TaskDto }) => {
  const theme = useTheme();
  const styles = useStyles();
  const rowData = props.data;
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event: any) {
    setContextMenuTask(rowData);

    show({
      event: event,
    });
  }

  const { color, overdueIcon, recurringIcon, formattedDate } =
    calculateTaskDueDate(rowData);

  return (
    <View style={[styles.cellContainer, styles.taskTimeContainer]}>
      {recurringIcon && (
        <Icon icon={recurringIcon} color={theme.palette.gray[400]} size={14} />
      )}
      {overdueIcon && <Icon icon={overdueIcon} color={color} size={14} />}
      <Text
        style={[
          styles.textEllipsis,
          {
            color: color,
          },
        ]}
      >
        {formattedDate}
      </Text>
      <div onClick={(event) => handleContextMenu(event)}>
        <View>
          <Icon
            icon={ThreedotsVerticalIcon}
            color={theme.palette.gray[900]}
          ></Icon>
        </View>
      </div>
    </View>
  );
});

const AssignedRenderer = memo((props: { data: TaskDto }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      <Avatar
        size={24}
        name={
          rowData.assigned_user_id
            ? `${rowData.assigned_user_first_name} ${rowData.assigned_user_last_name}`
            : undefined
        }
      />
      {rowData.assigned_user_id ? (
        <Text style={[styles.assigneeName, styles.textEllipsis]} selectable>
          {rowData.assigned_user_first_name} {rowData.assigned_user_last_name}
        </Text>
      ) : (
        <Text style={styles.assigneeUnassignedText} selectable>
          Nobody
        </Text>
      )}
    </View>
  );
});

const SummaryRenderer = memo((props: { data: TaskDto }) => {
  const theme = useTheme();
  const styles = useStyles();

  const rowData = props.data;

  return (
    <View
      style={[
        styles.cellContainer,
        {
          // since gap do not exist in react native we are casting it with any
          gap: theme.getSpacing(1),
        } as any,
      ]}
    >
      <View
        style={[
          styles.cellContainer,
          {
            // since gap do not exist in react native we are casting it with any
            gap: theme.getSpacing(1),
          } as any,
        ]}
      >
        {/* Personal Indicator */}
        {rowData.visibility === TaskVisibility.Personal ? (
          <Icon icon={LockIcon} color={theme.palette.gray[500]} size={18} />
        ) : null}

        {/* Priority Badge */}
        {rowData.priority === TaskPriority.High ||
        rowData.priority === TaskPriority.Low ? (
          <TaskMetaItem
            icon={
              rowData.priority === TaskPriority.High
                ? ArrowUpCircleIcon
                : ArrowDownCircleIcon
            }
            color={
              rowData.priority === TaskPriority.High
                ? theme.palette.error[500]
                : theme.palette.primary[300]
            }
            label={
              rowData.priority.charAt(0).toUpperCase() +
              rowData.priority.slice(1)
            }
            labelColor={theme.palette.gray[700]}
            backgroundColor={theme.palette.white}
            borderColor={theme.palette.gray[300]}
          />
        ) : null}

        {rowData.type && (
          <TaskMetaItem
            label={rowData.type.title}
            labelColor={theme.palette.gray[100]}
            backgroundColor={rowData.type.color}
            borderColor={rowData.type.color}
          />
        )}
      </View>
      <Text style={styles.textEllipsis}>{rowData.summary}</Text>
    </View>
  );
});

const ActionButtonsRenderer = memo(
  (props: { data: TaskDto; node: RowNode }) => {
    const rowData = props.data;
    const styles = useStyles();
    const theme = useTheme();

    const { show: showStatusMenu } = useContextMenu({
      id: STATUS_MENU_ID,
    });

    const handleStatusContextMenu = (
      event: GestureResponderEvent,
      rowData: TaskDto,
    ) => {
      if (rowData.status === TaskStatus.Resolved) return;

      setContextMenuTask(rowData);
      showStatusMenu({ event: event as unknown as TriggerEvent });
    };

    return (
      <View style={[styles.cellContainer]}>
        <View style={styles.checkboxContainer}>
          <CheckboxInput
            onPress={() => props.node.setSelected(!props.node.isSelected())}
            checked={props.node.isSelected()}
            mode={CheckboxInputMode.FLAT}
          />
        </View>
        <IconButton
          icon={getActionButtonIcon(rowData.status)}
          color={getActionButtonColor(rowData.status)}
          logger={{ id: `resolve-task--${rowData.id}` }}
          onPress={(event) => {
            handleStatusContextMenu(event, rowData);
          }}
        />

        <IconButton
          icon={rowData.flagged ? BookmarkIconFilledIn : BookmarkIcon}
          disabled={rowData.status == TaskStatus.Resolved}
          color={rowData.flagged ? theme.palette.warning['800'] : undefined}
          logger={{ id: `flag-task--${rowData.id}` }}
          onPress={() => {
            updateTask(rowData.id, {
              flagged: !rowData.flagged,
            });
          }}
        />
      </View>
    );
  },
);

// Re-rendering the TasksDataTable component is very resource expensive and causes bad UX
// We want to avoid rerendering this component each time state changes for a parent component
// this is unnecessary as this component is stateless
// https://reactjs.org/docs/react-api.html#reactmemo
const TasksDataTable: FunctionComponent<
  PropsWithChildren<TasksDataTableProps>
> = memo(({ collapseSidebar }) => {
  const theme = useTheme();

  const gridApiRef = useRef<GridApi | undefined>();

  const setGridApi = (api: GridApi) => {
    gridApiRef.current = api;
    persistGridApi(api);
    persistCollapseSidebarMethod(collapseSidebar);
  };
  const isTaskUpdate = useTasksDataTableState((state) => state.isTaskUpdate);
  const taskDetails = useTasksDataTableState((state) => state.taskDetails);

  const [tasksPaginatedRemoteDatasource] = useState<PaginatedRemoteDatasource>(
    TaskService.tasksPaginatedRemoteDatasource,
  );

  const { getParams } = TaskService;
  const { current_location_id, current_user_id } = getParams();

  const [searchValue, setSearchValue] = useDebounceSearchTerm();

  const { filters, isCustomFilter, isBulkDisabledTab } = useTasksFiltersState(
    (state) => ({
      filters: state.filters,
      isCustomFilter: state.isCustomFilter,
      isBulkDisabledTab:
        state.activeTab === 'resolved' || state.activeTab === 'deleted',
    }),
    shallow,
  );

  useFocusEffect(
    useCallback(() => {
      // Manually triggering the back-end/server side request on filters change and clearing the selected/checked state
      gridApiRef.current?.dispatchEvent({ type: 'filterChanged' });
      gridApiRef.current?.deselectAll();

      if (advancedFiltersSet(filters)) {
        ampli.taskAdvancedFiltered({
          taskAdvancedFilter: JSON.stringify(filters),
          taskAdvancedFilterAppliedTime: new Date().toISOString(),
        });
      }

      if (filters.search_term) {
        ampli.taskSearched({
          taskSearchedValue: filters.search_term,
          taskSearchedTime: new Date().toISOString(),
        });
      }
    }, [filters]),
  );

  useFocusEffect(
    useCallback(() => {
      setSearchValue('');
    }, []),
  );

  const handleSelectAll = (checked: boolean) => {
    gridApiRef.current?.getRenderedNodes().forEach((node) => {
      node.setSelected(checked);
    });

    gridApiRef.current?.redrawRows();
  };

  // Each Column Definition results in one Column.
  // - to force a column's width to fixed: width, minWidth and maxWidth properties need to be set
  const [columnDefs] = useState([
    {
      width: 180,
      maxWidth: 180,
      headerName: 'Status',
      cellRenderer: ActionButtonsRenderer,
      cellStyle: {
        paddingLeft: 12,
        display: 'flex',
        alignItems: 'center',
      },
    },
    {
      width: 200,
      maxWidth: 200,
      // We are performing sorting by first name and last name, since we don't keep a full name value, the current task service GET endpoint has support for multiple parameter sorting and
      // can parse array of string or comma separated strings, which seems to be a better value for a column id
      colId: 'assigned_user_first_name,assigned_user_last_name',
      sortable: true,
      headerName: 'Assigned to',
      cellRenderer: AssignedRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      cellClass: 'data-grid-cell-wrapper-overflow',
    },
    {
      headerName: 'Summary',
      cellRenderer: SummaryRenderer,
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      cellClass: 'data-grid-cell-wrapper-overflow',
    },
    {
      width: 200,
      maxWidth: 200,
      headerName: 'Due Date',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      // The column Id value is the same as the back-end/Task entity property.
      colId: 'due_date',
      sortable: true,
      sort: 'asc',
      headerClass: 'data-grid-header-right-aligned',
      cellClass: 'data-grid-cell-wrapper-overflow',
      cellRenderer: TimeRenderer,
    },
  ] as (ColDef | ColGroupDef)[]);

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.showLoadingOverlay();
    setGridApi(event.api);
    // Sets auto height to the table
    // suitable for tables with limited amount of rows
    event.api.setDomLayout('autoHeight');
  };

  const advancedFiltersSet = (filters: TaskFilters) => {
    return (
      filters.assigned_user_id ||
      filters.created_by_user_id ||
      filters.due_date ||
      filters.max_due_date ||
      filters.min_due_date ||
      filters.priority ||
      filters.status ||
      filters.task_type_id
    );
  };

  return (
    <View style={{ width: '100%', zIndex: zIndexAuto }}>
      <TaskContextMenu menuId={MENU_ID}></TaskContextMenu>
      <TaskStatusContextMenu menuId={STATUS_MENU_ID} />
      <BulkActionConfirmationDialog />
      <BulkAssigneeDialog />
      <BulkDueDateDialog />
      <DataGrid
        isRichContent={true}
        gridOptions={{
          onGridReady: handleGridReady,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressRowClickSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: { sortable: false, menuTabs: [] },
          pagination: true,
          paginationPageSize: 10,
          cacheBlockSize: 10,
          rowModelType: 'serverSide',
          serverSideDatasource: tasksPaginatedRemoteDatasource,
          rowSelection: 'multiple',
          rowMultiSelectWithClick: true,
          loadingOverlayComponent: 'loadingIndicator',
          components: {
            loadingIndicator: LoadingOverlay,
          },
          loadingCellRendererParams: {
            size: 42,
          },
          loadingCellRendererSelector: (params) => ({
            component: NoLoading,
          }),
          getRowId(params) {
            return params.data?.id;
          },
          serverSideStoreType: 'partial',
          onCellClicked(event) {
            // We don't want to open the sidepanel if the user clicked on one of the actions buttons
            // unfortunately ag-grid propagates this event no matter what (eg. suppressRowClickSelection)
            // sidepanel won't trigger if the user clicks on the padding surrounding the action buttons @rtud
            if (
              event.column &&
              event.column.getColId() !== '0' &&
              event.column.getColId() !== 'due_date'
            ) {
              showTaskDetails(event.data.id);
              collapseSidebar(false);
            }
          },
          onGridSizeChanged() {
            gridApiRef.current?.sizeColumnsToFit();
          },

          context: {
            transformResponse(params) {
              enableFilters();
              persistTaskPage(params as TaskPageDto);

              setCounts({
                total: params.total,
                flagged: params.totalFlagged,
                unresolved: params.totalUnresolved,
                recurring: params.totalRecurring,
                personal: params.totalPersonal,
              });

              return params;
            },

            transformRequest(params) {
              gridApiRef.current?.showLoadingOverlay();

              return {
                ...params,
                ...filters,
                current_location_id,
                current_user_id,
              };
            },
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No tasks to show"
              subtitle="Please disable some filters or relax your search query."
              icon={
                <CheckCircleIcon size={100} color={theme.palette.gray[300]} />
              }
              addMargin={true}
            />
          ),
          onModelUpdated(event) {
            if (event.api.getModel().getRowCount() === 0) {
              gridApiRef.current?.showNoRowsOverlay();
            } else {
              gridApiRef.current?.hideOverlay();
              if (isTaskUpdate) {
                const node = gridApiRef.current?.getRowNode(
                  taskDetails?.id as string,
                );

                gridApiRef.current?.setFocusedCell(
                  node?.rowIndex as number,
                  '0',
                );
              }
            }
          },
          rowClassRules: {
            'task-row': () => true,
          },
        }}
        gridToolbarProps={{
          titleProps: {
            title: 'Tasks',
          },
          inputSearchProps: {
            size: 'lg',
            placeholder: 'Search',
            isClearable: true,
            inputValue: searchValue,
            onChange: setSearchValue,
          },
          toolbarContentBoxProps: {
            isVisible: isCustomFilter,
            title: getText('content-box-title'),
            description: composeFiltersMessage(),
          },
          iconActionButtonsProps: {
            maxActionToShow: 5,
            actionButtons: [
              {
                icon: CheckCircleIcon,
                logger: { id: 'resolve-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.Resolved,
                      modifierName: getText('resolved'),
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('task-resolve'),
                tooltipText: getText('mark-resolved'),
              },
              {
                icon: PlayCircleIcon,
                logger: { id: 'in-progress-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.InProgress,
                      modifierName: getText('in_progress'),
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('task-in-progress'),
                tooltipText: getText('mark-in-progress'),
              },
              {
                icon: BookmarkIcon,
                logger: { id: 'flag-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfNotFlaggedSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems
                          .filter((item) => !item.data.flagged)
                          .map((item) => item.data.id);

                    const idsOfFlaggedSelectedItems = selectedItems.map(
                      (item) => item.data.id,
                    );

                    if (
                      isSelectAllGlobal ||
                      idsOfNotFlaggedSelectedItems?.length
                    ) {
                      await persistBulkAction({
                        type: BulkActionType.FLAG,
                        affectedIds: idsOfNotFlaggedSelectedItems,
                        modifierName: 'Flagged',
                        isGlobalUpdate: isSelectAllGlobal,
                      });
                    } else {
                      await persistBulkAction({
                        type: BulkActionType.UNFLAG,
                        affectedIds: idsOfFlaggedSelectedItems,
                        modifierName: 'Not flagged',
                        isGlobalUpdate: isSelectAllGlobal,
                      });
                    }
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('task-flag'),
                tooltipText: getText('mark-flagged'),
              },
              {
                icon: CalendarIcon,
                logger: { id: 'change-due-date-bulk-tasks' },
                onPress: ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    persistBulkAction({
                      type: BulkActionType.DUE_DATE,
                      affectedIds: idsOfSelectedItems,
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('change-due'),
                tooltipText: getText('change-due'),
              },
              {
                icon: UserPlusIcon,
                logger: { id: 'assign-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    await persistBulkAction({
                      type: BulkActionType.ASSIGNEE,
                      affectedIds: idsOfSelectedItems,
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('assignee'),
                tooltipText: getText('change-assignee'),
              },
              {
                icon: MinusCircleIcon,
                logger: { id: 'unresolved-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.Unresolved,
                      isGlobalUpdate: isSelectAllGlobal,
                      modifierName: getText('unresolved'),
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('not-started'),
                tooltipText: getText('mark-unresolved'),
              },
              {
                icon: PauseCircleIcon,
                logger: { id: 'on-hold-bulk-tasks' },
                onPress: async ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    await persistBulkAction({
                      type: BulkActionType.STATUS,
                      affectedIds: idsOfSelectedItems,
                      modifier: TaskStatus.OnHold,
                      modifierName: getText('on_hold'),
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
                disabled: isBulkDisabledTab,
                text: getText('on-hold'),
                tooltipText: getText('mark-on-hold'),
              },
            ],
            onDeletePress: isBulkDisabledTab
              ? undefined
              : ({ api, isSelectAllGlobal }) => {
                  const selectedItems = api?.getSelectedNodes();

                  if (selectedItems && selectedItems.length > 0) {
                    const idsOfSelectedItems = isSelectAllGlobal
                      ? undefined
                      : selectedItems.map((item) => item.data.id);

                    persistBulkAction({
                      type: BulkActionType.DELETE,
                      affectedIds: idsOfSelectedItems,
                      isGlobalUpdate: isSelectAllGlobal,
                    });
                  }
                },
            deleteTooltipText: getText('delete-task'),
          },
          onSelectAll: handleSelectAll,
        }}
      />
    </View>
  );
});

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: 20,
    marginTop: theme.getSpacing(4),
    marginBottom: theme.getSpacing(2),
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  taskMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  taskTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    gap: theme.getSpacing(1),
  },
  taskTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    gap: theme.getSpacing(1),
    alignContent: 'center',
  },
  menuOptionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: theme.getSpacing(1),
    alignItems: 'center',
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    marginLeft: theme.getSpacing(1),
  },
  assigneeName: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  assigneeUnassignedText: {
    color: theme.palette.gray[500],
  },
  // Styling the checkbox input to have the same dimensions as the IconButton.
  checkboxContainer: {
    width: theme.getSpacing(4),
    height: theme.getSpacing(4),
    margin: theme.getSpacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  alertPosition: {
    marginTop: -theme.getSpacing(4),
  },
}));

interface TasksDataTableProps {
  collapseSidebar: (collapsed?: boolean) => void;
}

export default TasksDataTable;

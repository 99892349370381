import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SubmissionMetadataDto } from '@digitalpharmacist/forms-service-client-axios';
import {
  GridReadyEvent,
  GridApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { TrayIcon } from 'assets/icons';
import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { DataGrid } from 'assets/components/data-grid';
import { View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import {
  formatDate,
  formatTimeDate,
  calculatePatientAge,
} from '../../common/datetime-utils';
import { Text } from 'assets/components/text';
import '../../common/data-grid-overrides.css';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useDebounce } from 'assets/hooks';
import NoResultsOverlay from '../../components/NoResultsOverlay';
import FormsService from '../../api/FormsService';
import { useAppStateStore } from '../../store/app-store';
import { getText } from 'assets/localization/localization';

const PatientRenderer = (props: { data: SubmissionMetadataDto }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <View style={styles.cellContainer}>
      <View style={styles.nameDobContainer}>
        <Text
          style={rowData.is_new && rowData.name ? styles.boldText : null}
          selectable
        >
          {rowData.name ? rowData.name : '-'}
        </Text>
      </View>
    </View>
  );
};

const NoLoading: FunctionComponent = () => {
  return <View style={{ height: '100%' }}></View>;
};

const BirthDateRenderer = (props: { data: SubmissionMetadataDto }) => {
  const rowData = props.data;

  return (
    <>
      <Text>
        {rowData.date_of_birth
          ? `${formatDate(rowData.date_of_birth)} (${calculatePatientAge(
              rowData.date_of_birth,
            )})`
          : '-'}
      </Text>
    </>
  );
};

const DateTimeRenderer = (props: { data: SubmissionMetadataDto }) => {
  const styles = useStyles();
  const rowData = props.data;

  return (
    <Text style={rowData.is_new && styles.boldText}>
      {formatTimeDate(rowData.created_at)}
    </Text>
  );
};

const FormNameRenderer = (props: { data: SubmissionMetadataDto }) => {
  const styles = useStyles();

  const rowData = props.data;
  return (
    <Text style={rowData.is_new && styles.boldText}>{rowData.form_name}</Text>
  );
};

const SubmissionsDataTable: FunctionComponent<
  SubmissionsDataTableProps
> = () => {
  const theme = useTheme();
  const [searchValue, setSearch] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue); // Debounce delay defaults to 500ms
  const [gridApi, _setGridApi] = useState<GridApi>();
  const { locationId } = useAppStateStore();

  const gridApiRef = useRef(gridApi);

  const setGridApi = (api: GridApi) => {
    gridApiRef.current = api;
    _setGridApi(api);
  };

  useFocusEffect(
    useCallback(() => {
      // Manually triggering the filter request in order to implement the input debounce
      gridApi?.dispatchEvent({ type: 'filterChanged' });
    }, [debouncedValue]),
  );

  useFocusEffect(
    useCallback(() => {
      gridApiRef.current?.refreshServerSideStore({ purge: true });
    }, []),
  );

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      maxWidth: 320,
      field: 'name',
      cellRenderer: PatientRenderer,
      headerName: getText('patient'),
      cellStyle: {
        display: 'flex',
      },
    },
    {
      maxWidth: 200,
      field: 'date_of_birth',
      headerName: getText('dob-age'),
      cellRenderer: BirthDateRenderer,
    },
    {
      field: 'form_name',
      headerName: getText('form-name'),
      cellRenderer: FormNameRenderer,
    },
    {
      maxWidth: 220,
      field: 'created_at',
      headerName: getText('submitted'),
      sort: 'desc',
      sortable: true,
      cellRenderer: DateTimeRenderer,
      headerClass: 'data-grid-header-right-aligned',
      cellStyle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
  ] as (ColDef | ColGroupDef)[]);

  const navigation = useNavigation<FormsDrawerNavigationProp>();

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.showLoadingOverlay();
    setGridApi(event.api);

    event.api.setDomLayout('autoHeight');
  };

  // Memoizing the serverSideDatasource in order to keep the DataGrid from sending new request on every search input change
  // this is due to the searchValue state changing causing the component to rerender which causes the DataGrid to send new request.
  const memoizedServerSideDatasource = useMemo(() => {
    return FormsService.getSubmissionsPaginatedDatasource(locationId);
  }, [locationId]);

  return (
    <View style={{ width: '100%' }}>
      <DataGrid
        gridOptions={{
          onGridReady: handleGridReady,
          columnDefs: columnDefs,
          enableCellTextSelection: true,
          suppressMovableColumns: true,
          suppressContextMenu: true,
          defaultColDef: {
            sortable: false,
            menuTabs: [],
          },
          pagination: true,
          paginationPageSize: 10,
          cacheBlockSize: 10,
          rowModelType: 'serverSide',
          serverSideDatasource: memoizedServerSideDatasource,
          serverSideStoreType: 'partial',
          onRowClicked: (row: { data: SubmissionMetadataDto }) => {
            navigation.navigate('submission-view', {
              submissionId: row.data.submission_id,
              formId: row.data.form_id,
            });
          },
          loadingOverlayComponent: 'loadingIndicator',
          components: {
            loadingIndicator: LoadingOverlay,
          },
          loadingCellRendererSelector: () => ({
            component: NoLoading,
          }),
          onGridSizeChanged() {
            gridApiRef.current?.sizeColumnsToFit();
          },
          onPaginationChanged(event) {
            if (event.newPage) {
              gridApiRef.current?.dispatchEvent({ type: 'selectionChanged' });
            }
          },
          context: {
            transformRequest(params) {
              gridApiRef.current?.showLoadingOverlay();

              if (searchValue) {
                return { ...params, keyword: searchValue };
              }
              return params;
            },
          },
          noRowsOverlayComponent: () => (
            <NoResultsOverlay
              title="No submissions found"
              icon={<TrayIcon size={100} color={theme.palette.gray[300]} />}
              addMargin={true}
            />
          ),
          onModelUpdated(event) {
            if (event.api.getModel().getRowCount() === 0) {
              gridApiRef.current?.showNoRowsOverlay();
            } else {
              gridApiRef.current?.hideOverlay();
            }
          },
        }}
        gridToolbarProps={{
          titleProps: {
            title: 'Submissions',
          },
          inputSearchProps: {
            size: 'lg',
            placeholder: 'Search submissions',
            onChange(value) {
              // Minimum length of the keyword must be 3 and resetting the filter if any value gets deleted
              if (!value.length || value.length > 2) {
                setSearch(value);
              }
            },
          },
        }}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  cellContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  nameDobContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.getSpacing(0.5),
  },
  boldText: {
    ...theme.fonts.medium,
  },
}));

interface SubmissionsDataTableProps {}

export default SubmissionsDataTable;

import { FunctionComponent, useEffect, useState } from 'react';
import { CreateConversationData, NewChatModal } from '../NewChatModal';
import { useInboxState } from '../../inbox-store/inbox-store';
import { View } from 'react-native';
import { v4 } from 'uuid';
import { setLoading } from '../../loading-store/loading-actions';
import {
  ERROR_FILES_SENDING_MODAL,
  LOADING_CONVERSATION_CREATING,
} from '../../data';
import {
  removeSelectedConversation,
  removeSelectedPatient,
} from '../../inbox-store/inbox-actions';
import { useLoadingState } from '../../loading-store/loading-store';
import { setError } from '../../error-store/error-actions';
import { ErrorStatus } from '../../error-store/error-store';
import { getText } from 'assets/localization/localization';
import { useUserState } from '../../../../store/user-store';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import unifiedCommsService from '../../../../api/UnifiedCommsService';
import { IUploadFilesResult } from '../../types';
import FileStorageService from '../../../../api/FileStorageService';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { Button } from 'assets/components/button';
import { useAppStateStore } from '../../../../store/app-store';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { MessageCircleIcon } from 'assets/icons';
import theme from 'assets/theme';

export interface NewChatButtonProps {
  patient?: LocationPatientRecordDto;
}

export const NewChatButton: FunctionComponent<NewChatButtonProps> = ({
  patient,
}) => {
  const { locationId, pharmacyId } = useAppStateStore();
  const user = useUserState();
  const { selectedPatient, templates } = useInboxState();

  const { loadingObject } = useLoadingState();
  const [isOpenNewChatModal, setIsOpenNewChatModal] = useState<boolean>(false);

  useEffect(() => {
    if (!patient) return;

    useInboxState.setState({ selectedPatient: patient });
  }, [patient]);

  const onCancelModal = () => {
    setIsOpenNewChatModal(false);
  };

  const onConversationCreate = async (data: CreateConversationData) => {
    setLoading(LOADING_CONVERSATION_CREATING, true);

    const filesUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      data.attachments,
    );

    if (filesUploadResult.isError) {
      setError(
        ERROR_FILES_SENDING_MODAL,
        ErrorStatus.ERROR,
        getText('files-sending-error-try-again'),
      );
      setLoading(LOADING_CONVERSATION_CREATING, false);
      return;
    }

    const createConversationDto = {
      author_id: String(user.data?.id),
      author_type: AuthorType.Pharmacy,
      subject: data.subject,
      patient_viewed_all_messages: false,
      pharmacy_viewed_all_messages: true,
      content: data.message,
      attachments: filesUploadResult.filesData.length
        ? filesUploadResult.filesData
        : [],
    };

    await unifiedCommsService.createConversation(
      pharmacyId,
      locationId,
      data.patient,
      createConversationDto,
    );

    setLoading(LOADING_CONVERSATION_CREATING, false);
    onCancelModal();

    if (selectedPatient) {
      removeSelectedPatient();
      removeSelectedConversation();
    }
  };

  async function uploadFiles(
    locationId: string,
    pharmacyId: string,
    files: File[] | undefined,
  ): Promise<IUploadFilesResult> {
    const result: IUploadFilesResult = {
      isError: false,
      filesData: [],
    };

    if (!files || !files.length) {
      return result;
    }

    for (const file of files) {
      try {
        const newName = v4();
        const extension = FileStorageService.getFileExtension(file.name);
        const fileName = `${newName}.${extension}`;

        const responseWriteUrl = await FileStorageService.writeUrl(
          LocationCategory.DirectMessage,
          locationId,
          fileName,
          pharmacyId,
        );

        await FileStorageService.uploadFile(file, responseWriteUrl.data.url);

        result.filesData.push({
          name: file.name,
          stored_filename: fileName,
        });
      } catch (error) {
        console.error(`Error uploading file ${file.name}. Error: `, error);
        result.isError = true;
      }
    }

    return result;
  }

  const handleButtonPress = () => {
    setIsOpenNewChatModal(true);
  };

  return (
    <View>
      <Button
        logger={{ id: NewChatButtonLoggerId }}
        hierarchy="secondary-gray"
        size="large"
        style={{ width: 150 }}
        onPress={handleButtonPress}
        icon={MessageCircleIcon}
      >
        Chat
      </Button>
      <NewChatModal
        show={isOpenNewChatModal}
        onConversationCreate={onConversationCreate}
        onCancel={onCancelModal}
        recipient={selectedPatient}
        templates={templates}
        isConversationCreating={
          LOADING_CONVERSATION_CREATING in loadingObject &&
          loadingObject[LOADING_CONVERSATION_CREATING].isLoading
        }
      />
    </View>
  );
};

export const NewChatButtonLoggerId = 'NewChatButton';

export default NewChatButton;

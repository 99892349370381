import { DataGrid } from 'assets/components/data-grid';
import { makeStyles, useTheme } from 'assets/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  GridApi,
} from '@ag-grid-community/core';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import useViewSidebarStore from './view-sidebar-store';
import { Icon } from 'assets/components/icon';
import { Avatar } from 'assets/components/avatar';
import { AlertCircleIcon } from 'assets/icons';
import prescriptionService from '../../api/PrescriptionService';
import { useProSidebar } from 'react-pro-sidebar';
import { formatTimeDate } from '../../common/datetime-utils';
import { useAppStateStore } from '../../store/app-store';
import { RefillStatus } from '@digitalpharmacist/pms-integration-service-client-axios';
import usePatientStore from '../patients/patient-store';
import {
  LocationPatientRecordDtoWithFullName,
  SidebarDetails as SidebarPatientDetails,
} from '../patients/patient-types';
import { RefillSubmittedDto } from '@digitalpharmacist/prescription-service-client-axios';
import { getRxStatusText, getWorkflowStatusText } from '../../../utils';
import PrescriptionService from '../../api/PrescriptionService';
import RefillDetailSidebar from './RefillDetailSidebar';
import PatientDetailSidebar from '../patients/PatientDetailSidebar';
import { RefillSubmissionStatusFilter } from './components/refill-submissions-status-filter';

export default function RefillSubmissions() {
  const styles = useStyles();
  const theme = useTheme();
  const { locationId } = useAppStateStore();
  const { sidebarDetails, setSidebarDetails } = useViewSidebarStore();
  const {
    sidebarDetails: patientSidebarDetails,
    setSidebarDetails: setPatientSidebarDetails,
  } = usePatientStore();

  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const { collapseSidebar } = useProSidebar();
  const [isPassedFilter, setIsPassedFilter] = useState<
    '' | 'rejected' | 'accepted'
  >('');
  const [columnDefs] = useState<Array<ColDef | ColGroupDef>>([
    {
      field: 'first_name',
      headerName: getText('patient'),
      cellRenderer: FullNameRenderer,
    },
    {
      field: 'rx_number',
      headerName: getText('rx-number'),
    },
    {
      field: 'ingress_method',
      headerName: getText('channel'),
      cellRenderer: ChannelFieldRenderer,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'fulfillment_method',
      headerName: getText('method'),
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      field: 'refill_status',
      headerName: getText('status'),
      cellRenderer: StatusFieldRenderer,
    },
    {
      field: 'submitted_at',
      headerName: getText('date-submitted'),
      headerClass: 'date-submitted-header',
      cellRenderer: DateFieldRenderer,
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  ]);

  const fillRefillSidebarDetails = (
    selectedRefillSubmission: RefillSubmittedDto,
  ) => {
    setPatientSidebarDetails(undefined as unknown as SidebarPatientDetails);

    void prescriptionService
      .fillPatientDetailsSidebar(locationId, selectedRefillSubmission)
      .then((response) => {
        setSidebarDetails(response);
      });
  };

  const fillPatientSideBarDetails = async () => {
    const locationPatientRecord = sidebarDetails?.patientDetails;

    if (!locationPatientRecord) return;

    const prescriptions = await prescriptionService.findPrescriptions(
      locationId,
      locationPatientRecord.id,
    );

    const locationPatientRecordWithFullName: LocationPatientRecordDtoWithFullName =
      {
        ...locationPatientRecord,
        full_name: `${locationPatientRecord.first_name} ${locationPatientRecord.last_name}`,
      };

    setPatientSidebarDetails({
      patientNumberInList: -1,
      locationPatientRecord: locationPatientRecordWithFullName,
      patientPrescriptions: prescriptions,
    });
  };

  const handlePatientDetailsPress = () => {
    void fillPatientSideBarDetails();
  };

  const handlePatientDetailsCollapse = () => {
    setPatientSidebarDetails(undefined as unknown as SidebarPatientDetails);
  };

  useEffect(() => {
    gridApi?.refreshServerSideStore();
  }, [isPassedFilter]);

  const memoizedServerSideDatasource = useMemo(() => {
    return PrescriptionService.getRefillSubmissionsDatasource(locationId);
  }, [locationId]);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <DataGrid
          gridOptions={{
            columnDefs: columnDefs,
            enableCellTextSelection: true,
            suppressMovableColumns: true,
            suppressContextMenu: true,
            defaultColDef: { sortable: true, menuTabs: [] },
            pagination: true,
            paginationPageSize: 10,
            maxBlocksInCache: 1,
            loadingOverlayComponent: 'loadingIndicator',
            loadingOverlayComponentParams: {
              color: theme.colors.pharmacyPrimary,
            },
            components: {
              loadingIndicator: LoadingIndicator,
            },
            rowSelection: 'single',
            suppressCellFocus: true,
            colResizeDefault: 'shift',
            onRowClicked(event) {
              collapseSidebar(false);
              fillRefillSidebarDetails(event.node.data);
            },
            onGridReady(event) {
              setGridApi(event.api);
            },
            rowModelType: 'serverSide',
            serverSideStoreType: 'partial',
            serverSideDatasource: memoizedServerSideDatasource,
            context: {
              transformRequest(params) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return {
                  ...params,
                  refill_status:
                    isPassedFilter !== '' ? isPassedFilter : undefined,
                };
              },
            },
          }}
          gridToolbarProps={{
            headerChildren: (
              <RefillSubmissionStatusFilter
                value={isPassedFilter}
                onChange={setIsPassedFilter}
              />
            ),
            titleProps: {
              title: getText('refill-submissions'),
            },
            inputSearchProps: {
              size: 'lg',
              placeholder: getText('search'),
            },
          }}
        />
      </View>
      {sidebarDetails && !patientSidebarDetails && (
        <RefillDetailSidebar
          onPatientDetailsPress={handlePatientDetailsPress}
        />
      )}
      {sidebarDetails && patientSidebarDetails && (
        <PatientDetailSidebar onCollapse={handlePatientDetailsCollapse} />
      )}
    </View>
  );
}

export const FullNameRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { patient_first_name, patient_last_name } =
    params.data as RefillSubmittedDto;
  const fullName = `${patient_first_name} ${patient_last_name}`;

  return (
    <View style={styles.iconContainer}>
      <View
        style={{ justifyContent: 'center', marginRight: theme.getSpacing(1) }}
      >
        <Avatar name={fullName} size={32} color={theme.palette.gray[200]} />
      </View>
      <div>{fullName} </div>
    </View>
  );
};

export const StatusFieldRenderer = (params: ICellRendererParams) => {
  const styles = useStyles();
  const theme = useTheme();
  const { refill_status, rx_status, workflow_status } =
    params.data as RefillSubmittedDto;

  const workflowStatusText = getWorkflowStatusText(workflow_status);
  const rxStatusText = getRxStatusText(rx_status);

  if (refill_status === RefillStatus.Accepted) {
    return <Text numberOfLines={1}>{workflowStatusText}</Text>;
  } else {
    return (
      <View style={styles.iconContainer}>
        <View style={styles.icon}>
          <Icon icon={AlertCircleIcon} size={16} color={theme.colors.error} />
        </View>
        <Text numberOfLines={1}>{rxStatusText}</Text>
      </View>
    );
  }
};

export const ChannelFieldRenderer = (params: ICellRendererParams) => {
  const channel = params.value;

  return <div>{channel === 'ivr' ? channel.toUpperCase() : channel}</div>;
};

export const DateFieldRenderer = (params: ICellRendererParams) => {
  return <div>{formatTimeDate(params.value)}</div>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.getSpacing(4),
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 46,
  },
  icon: {
    justifyContent: 'center',
    marginRight: theme.getSpacing(1),
  },
}));

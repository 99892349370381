import { RefillStatus } from '@digitalpharmacist/prescription-service-client-axios';
import { RadioButtonInputMode } from 'assets/components/radio-button-group/RadioButton';
import { RadioButtonGroupInput } from 'assets/components/radio-button-group/RadioButtonGroupInput';
import { FunctionComponent } from 'react';
import { View } from 'react-native';

export type RefillSubmissionStatusFilterProps = {
  value: RefillStatus | '';
  onChange: (value: RefillStatus | '') => void;
};

export const RefillSubmissionStatusFilter: FunctionComponent<
  RefillSubmissionStatusFilterProps
> = ({ value, onChange }) => {
  return (
    <View>
      <RadioButtonGroupInput
        isHorizontal
        mode={RadioButtonInputMode.FLAT}
        value={value}
        values={[
          {
            text: 'Not passed',
            value: RefillStatus.Rejected,
          },
          {
            text: 'Passed',
            value: RefillStatus.Accepted,
          },
          {
            text: 'All',
            value: '',
          },
        ]}
        onValueChange={(value: RefillStatus | '') => {
          onChange(value);
        }}
      />
    </View>
  );
};
